<template>
    <div class="content">
        <commonTitle></commonTitle>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
                 v-loading="loading">
            <el-form-item label="小区名称" >
                <el-input v-model="ruleForm.villageName" placeholder="请输入小区名称" disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="楼幢号" >
                <el-input v-model="ruleForm.towerNum" placeholder="请输入楼幢号" disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="单元名称" >
                <el-input v-model="ruleForm.unitName" placeholder="请输入单元名称" disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="房号" prop="houseNum">
                <el-input v-model="ruleForm.houseNum" placeholder="请输入房号"></el-input>
            </el-form-item>
            <el-form-item label="建筑面积" prop="coveredarea">
                <el-input v-model="ruleForm.coveredarea" placeholder="请输入建筑面积"></el-input>
            </el-form-item>
            <el-form-item label="购房款" prop="housPurchase">
                <el-input v-model="ruleForm.housPurchase" placeholder="请输入购房款"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" size="small" @click="submitForm('ruleForm')">保存</el-button>
                <el-button @click="back" size="small">返回</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import commonTitle from "../table/commonTitle";

    export default {
        name: "addHouse",
        components: {
            commonTitle
        },
        data() {
            return {
                ruleForm: {
                    villageName:'',//小区名称
                    towerNum:'',//楼幢号
                    unitName:'',//单元名称
                    villageId: '',//小区id
                    towerId: '',//楼幢Id
                    unitId: '',//单元Id
                    houseNum: '',  //楼幢号
                    coveredarea: '',//建筑面积
                    housPurchase: ''//购房款
                },
                rules: {
                    houseNum: [
                        {required: true, message: '请输入房号', trigger: 'change'},
                    ], coveredarea: [
                        {required: true, message: '请输入建筑面积', trigger: 'change'},
                    ], 
                },
                towerTypeList: [],  //楼幢类型
                dtList: [],        //电梯类型
                payList: [],         //缴费标准
                url: this.$Config.base_server,
                loading: false
            };
        }, mounted() {
            this.ruleForm.villageName = JSON.parse(localStorage.getItem("villageData")).villageName; //获取小区名称  并赋值
            this.ruleForm.towerNum = JSON.parse(localStorage.getItem("towerData")).towerNum; //获取楼幢号  并赋值
            this.ruleForm.unitName = JSON.parse(localStorage.getItem("unitData")).unitName; //获取单元名称  并赋值
            this.common();
        }, methods: {
            common() {
                //初始化数据
                //初始化数据
                var _this = this; //声明一个变量指向Vue实例this，保证作用域一致
                _this.ruleForm.villageId = JSON.parse(localStorage.getItem("villageData")).villageId; //获取小区id  并赋值
                _this.ruleForm.towerId = JSON.parse(localStorage.getItem("towerData")).towerId; //获取楼幢id  并赋值
                _this.ruleForm.unitId = JSON.parse(localStorage.getItem("unitData")).unitId; //获取单元Id  并赋值

            }
            , selectOne(event, item) {
            }
            , submitForm(formName) {
                var _this = this;
                _this.loading = true;
                //立即创建按钮
                this.$refs[formName].validate(function (valid) {
                    if (valid) {
                        $.ajax({
                            url: _this.url + "/api-public/opehouse/save?access_token=" + localStorage.getItem("token"),
                            type: 'post',
                            data: JSON.stringify(_this.ruleForm),
                            contentType: 'application/json',
                            success: function (result) {
                                if (result.resp_code == 0) {
                                    _this.loading = false;
                                    //成功后
                                    _this.$confirm('户室添加成功,是否还要继续添加?', '提示', {
                                        confirmButtonText: '继续',
                                        cancelButtonText: '不在继续',
                                        type: 'warning'
                                    }).then(function () {
                                        //清空表单信息
                                        _this.$refs[formName].resetFields();
                                    }).catch(function () {
                                        _this.$router.go(-1); //返回上层路由
                                    });

                                } else {
                                    _this.loading = false;
                                    //添加失败
                                    _this.$message({
                                        message: result.resp_msg,
                                        dangerouslyUseHTMLString: true,
                                        type: 'warning'
                                    });
                                }
                            }
                        });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            }, back() {
                //返回按钮
                this.$router.go(-1);
            }
        }
    }
</script>

<style scoped>

    .content {
        padding: 20px 111px;
        background: #fff;
    }

    .el-form {
        width: 600px;
        margin: 0 auto;
    }
</style>